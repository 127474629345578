<template>
<div>
        <loading :active.sync="loading"></loading>
        <!--<link rel="stylesheet" href="https://unpkg.com/@coreui/icons/css/all.min.css">-->
        <div class="row"><div class="col-md-12">
          <b-card style="max-width:860px">
            <div slot="header" data-v-step="1">
            <div class="row">
              <div class="col-md-4">
                <strong>Câmbio</strong>
              </div>
              <div class="col-md-8" style="text-align:right;">
                <a
                  class="btn btn-link" 
                  href="javascript:;"
                  @click="cotacoesAtualizar()" >
                  <CIcon name="cil-money" />&nbsp;<small>Atualizar</small>
              </a>
                <b-button variant="link" size="sm" @click.stop="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6" data-v-step="1">
              <h5>Cotação de hoje<span v-html="cotData"></span></h5>
              <div id="cotacoes" v-html="cot"></div>
              <button type="button" data-v-step="3" class="btn btn-sm" @click="cotacoesCopiar()">Quero usar essas cotações ></button><br>
              <small>Fonte: <a href="https://hgbrasil.com/status/finance" target="_blank">https://hgbrasil.com/status/finance</a></small>
            </div>
            <div class="col-6">
              <h5>Cotação usada pelo Gera Preço atualmente</h5>
              <table class="table" style="max-width: 300px;">
                <tbody>
                <tr>
                  <td style="width:70%;font-weight:bold;">Dólar Americano ($ - USD)</td>
                  <td data-v-step="2" style="width:30%;"><vue-autonumeric  class="form-control verde direita" :options="dinheiro2" v-model="cota[0]"></vue-autonumeric></td>
                </tr>
                <tr>
                  <td style="width:70%;font-weight:bold;">Euro (€ - EUR)</td>
                  <td style="width:30%;"><vue-autonumeric  class="form-control verde direita" :options="dinheiro2" v-model="cota[1]"></vue-autonumeric></td>
                </tr>
                <tr>
                  <td style="width:70%;font-weight:bold;">Libra Esterlina (£ - GBP)</td>
                  <td style="width:30%;"><vue-autonumeric  class="form-control verde direita" :options="dinheiro2" v-model="cota[2]"></vue-autonumeric></td>
                </tr>
                <tr>
                  <td style="width:70%;font-weight:bold;">Yen Japonês (¥ - JPY)</td>
                  <td style="width:30%;"><vue-autonumeric  class="form-control verde direita" :options="dinheiro2" v-model="cota[3]"></vue-autonumeric></td>
                </tr>
                <tr>
                  <td style="width:70%;font-weight:bold;">Renminbi Chinês (元 - CNY)</td>
                  <td style="width:30%;"><vue-autonumeric  class="form-control verde direita" :options="dinheiro2" v-model="cota[4]"></vue-autonumeric></td>
                </tr>
                <tr>
                  <td style="width:70%;font-weight:bold;">Dólar Canadense ($ - CAD)</td>
                  <td style="width:30%;"><vue-autonumeric  class="form-control verde direita" :options="dinheiro2" v-model="cota[5]"></vue-autonumeric></td>
                </tr>
                </tbody>
              </table>
              <button type="button" class="btn btn-success" @click="cotacoesSalvar()">Salvar</button>
            </div>
          </div>

        
          </b-card>
          <b-modal size="lg" id="ajuda" hide-footer title="Câmbio">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/kOmejt3tiAY?rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>


        </div></div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'

export default {
  data () {
    return {
      stepsOptions: {
        labels: {
          buttonSkip: 'Sair da ajuda',
          buttonPrevious: 'Anterior',
          buttonNext: 'Próximo',
          buttonStop: 'OK!'
        }
      },
      steps: [
        {
          target: '[data-v-step="1"]',
          header: {
            title: 'Tabela de Câmbio',
          },
          content: `O Gera Preço permite que você trabalhe com várias moedas para o cadastro de insumos e serviços de terceiros. O câmbio informado aqui é utilizado lá e reflete também no valor dos seus produtos, caso utilize insumos de moedas diferentes.`
        },
        {
          target: '[data-v-step="2"]',
          content: 'Basta preencher aqui a o valor da cotação desejada, em REAIS.',
          params: {
            placement: 'top'
          }
        },
        {
          target: '[data-v-step="3"]',
          content: 'Se acha melhor utilizar sempre a cotação do dia, é necessário clicar neste botão para sincronizar os valores.',
          params: {
            placement: 'top'
          }
        }
      ],
      cotData: '',
      cot: 'Carregando...',
      cotacaoAtual: [],
      cotacaoUser: [],
      cota: [0,0,0,0,0,0],
      sessao: '',
      loading: false,
      optionsMoeda: [
        {value: 'BRL', text: 'R$'},
        {value: 'USD', text: 'US$'},
        {value: 'EUR', text: '€'},
        {value: 'GBP', text: '£'},
        {value: 'JPY', text: '¥'},
        {value: 'CNY', text: '元'},
        {value: 'CAD', text: 'CAD$'}
      ],
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      percentual: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      decimal2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 3
      }
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.loading = true
    this.verificaSessao()
  },
  filters: {
    pad: function (n, width, z) {
      z = z || '0'
      n = n + ''
      width = width || '3'
      return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
    }
  },
  methods: {
    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if (!this.permissao('TABELAS_MATERIAPRIMA'))
        {
          this.$notify({
            type: 'danger',
            title: 'Sem permissão',
            text: 'Você não possui permissão para acessar esta área.'
          })
          return false
        }
        this.$http.get(this.URLApp + 'tabelas/getCotacao/' + this.$session.get('grupo')).then(
          (r) => {
            this.cotacaoUser = r.body
            console.log(this.cotacaoUser)
            this.cotacoes()
            this.cota[0] = this.cotacaoUser['results']['currencies']['USD']['buy']
            this.cota[1] = this.cotacaoUser['results']['currencies']['EUR']['buy']
            this.cota[2] = this.cotacaoUser['results']['currencies']['GBP']['buy']
            this.cota[3] = this.cotacaoUser['results']['currencies']['JPY']['buy']
            this.cota[4] = this.cotacaoUser['results']['currencies']['CNY']['buy']
            this.cota[5] = this.cotacaoUser['results']['currencies']['CAD']['buy']
            this.loading = false
          },
          (r) => {
            this.cotData = '(Falha ao buscar tabela de cotações do usuário)'
            this.loading = false
          }
        )
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    },
    cotacoes: function () {
      this.$http.get(this.URLApp + 'tabelas/updateCotacao')
      this.$http.get(this.URLApp + 'files/cotacao.txt?' + new Date().getTime()).then(
        (p) => {
          var c = p.body
          this.cotData = ''
          this.cot = '<ul>'
          this.cot += '<li><strong>Dólar Americano ($ - USD)</strong><br>R$ ' + c['results']['currencies']['USD']['buy'] + '</li>'
          this.cot += '<li><strong>Euro (€ - EUR)</strong><br>R$ ' + c['results']['currencies']['EUR']['buy'] + '</li>'
          this.cot += '<li><strong>Libra Esterlina (£ - GBP)</strong><br>R$ ' + c['results']['currencies']['GBP']['buy'] + '</li>'
          this.cot += '<li><strong>Yen Japonês (¥ - JPY)</strong><br>R$ ' + c['results']['currencies']['JPY']['buy'] + '</li>'
          this.cot += '<li><strong>Renminbi Chinês (元 - CNY)</strong><br>R$ ' + c['results']['currencies']['CNY']['buy'] + '</li>'
          this.cot += '<li><strong>Dólar Canadense ($ - CAD)</strong><br>R$ ' + c['results']['currencies']['CAD']['buy'] + '</li>'
          this.cotacaoAtual = c
        },
        (p) => {
          this.cotData = '(Falha ao buscar tabela de cotações)'
          this.cot = JSON.stringify(p)
        }
      )
    },
    cotacoesAtualizar: function () {
      this.$http.get(this.URLApp + 'tabelas/updateCotacao/force').then(
        (a) => { document.location.reload() },
        (a) => { alert('Falha ao atualizar...') }
      )
    },
    cotacoesSalvar: function () {
      var usoParams = {
        g: this.$session.get('grupo'),
        t: this.cota
      }
      this.$http.post(this.URLApp + 'tabelas/writeCotacao/' + this.$session.get('grupo'), usoParams).then(
        (k) => {
          this.$notify({
            type: 'success',
            title: 'Câmbio salvo!',
            text: ''
          })
        },
        (k) => {
          this.$notify({
            type: 'danger',
            title: 'Falha ao salvar',
            text: 'Contate o suporte da aplicação ou verifique sua conexão.'
          })
        }
      )
    },
    cotacoesCopiar: function () {
      this.$http.get(this.URLApp + 'tabelas/getCotacao/' + this.$session.get('grupo') + '/123456').then(
        (r) => {
          this.verificaSessao()
        }
      )
    }
  },
  components: {
    Loading,
    VueAutonumeric
  }
}
</script>